<template>
  <div class="login">
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="用户名"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <div style="position: fixed;top: 10rem;width: 100%">
      <van-loading type="spinner" vertical color="#1989fa" v-if="loading" />
    </div>
  </div>

</template>

<script>
import { Login,obtain } from '@/api'
export default {
  name: "login",
   data() {
    return {
      username: '',
      password: '',
      loading: false,
    };
  },
  created(){
    const str = navigator.userAgent.toLowerCase()
    if(str.match(/MicroMessenger/i) == 'micromessenger'){
        this.auth()
      }
  },
  methods: {
    onSubmit(values) {
      Login({username:this.username,password:this.password}).then((response) => {
        localStorage.setItem('token',response.data.access_token)
        this.$router.push({path:'/'})
      })
    },
    auth() {
      const code = this.GetUrlParame("code"); // 截取code，授权返回的code
      let appid = 'wxe45e400c8c7df754'; // 可以通过后台获取
      // let appid = 'wx52609847375ef30d'  // 测试的
      
      if (!code) {
          //没有code
          let authPageBaseUri =
              "https://open.weixin.qq.com/connect/oauth2/authorize";
          //这里的 redirect_uri 用的当前页面地址，记得用 encodeURIComponent 做一下编码，这儿不注意容易报错
          let authParams = "";
          authParams = `?appid=${appid}&redirect_uri=${encodeURIComponent(
              window.location.origin + "/#/login"
          )}&response_type=code&scope=snsapi_base&state=ceshi#wechat_redirect`;
          console.log(authPageBaseUri + authParams)
          console.log(window.location.href.split("#")[0] + "#/login")
          
          window.location.href = authPageBaseUri + authParams
          
      } else {
          //有code 拿到code去后台换数据 token openid等
          obtain({
            code: code
          }).then((response) => {
            this.loading = false
            localStorage.setItem('token',response.data.access_token)
            this.$router.push({path:'/'})
          })
      }
    },
        // 截取code
        GetUrlParame(parameName) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == parameName){return pair[1];}
            }
            return(false);
        }
  },
}
</script>

<style scoped lang="less">
.login {
  color: blue;
  .text {
    color: red;
  }
}

</style>
